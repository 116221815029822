<template>
  <el-container>
    <el-aside width="240px">
      <el-select v-model="deptId" filterable :style="{width:'100%',marginBottom:'5px'}" @change="handleDeptChange">
        <el-option
            v-for="dept in deptSelectData"
            :value="dept.id"
            :label="dept.deptName"></el-option>
      </el-select>
      <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto;height: 95%">
        <li v-for="(item,index) in adviserData" class="infinite-list-item"
            @click="handleCardStyle(index,item.accountId)">
          <AdviserCard :nick-name="item.nickName"
                       :total-amount="item.totalAmount"
                       :total-duration="item.totalDuration"
                       :dail-count-num="item.dailCountNum"
                       :order-count-num="item.orderCountNum"
                       :config-name="item.configName"
                       :index="index" :ref="`card-${index}`"></AdviserCard>
        </li>
      </ul>
    </el-aside>
    <el-container>
      <el-header>
        <el-row type="flex" justify="space-around" :gutter="10">
          <!--   订单管理中所有已完成订单状态的缴费金额的总计   -->
          <AnalysisCard tooltip="订单管理中所有已完成订单状态的缴费金额的总计" background="#409EFF" title="流水"
                        :content="dataAnalysisData.revenue / 100"></AnalysisCard>
          <!--   所有咨询师的所有状态的正式订单(不算补录订单)数量   -->
          <AnalysisCard tooltip="所有咨询师的所有状态的正式订单(不算补录订单)数量" background="#FF88A7" title="订单总量"
                        :content="dataAnalysisData.orderTotal" unit="个"></AnalysisCard>
          <!--   所有已成交的正式订单的数量   -->
          <AnalysisCard tooltip="所有已成交的正式订单的数量" background="#7B5EEA" title="成单总量"
                        :content="dataAnalysisData.orderSuccess" unit="个"></AnalysisCard>
          <!--   所有直呼双呼的接通的通话总时长   -->
          <AnalysisCard tooltip="所有直呼双呼的接通的通话总时长" background="#F19149" title="总拨打时长"
                        :content="formateTime(dataAnalysisData.calledTotal)"></AnalysisCard>
          <!--   所有直呼双呼的点击拨打次数统计   -->
          <AnalysisCard tooltip="所有直呼双呼的点击拨打次数统计" background="#FF88A7" title="拨打量" :content="dataAnalysisData.called"
                        unit="个"></AnalysisCard>
          <!--   所有拨打机会的接通量的统计   -->
          <AnalysisCard tooltip="所有拨打机会的接通量的统计" background="#7B5EEA" title="接通量"
                        :content="dataAnalysisData.connected" unit="个"></AnalysisCard>
          <!--   所有拨打接通成功>30s的通话数的统计   -->
          <AnalysisCard tooltip="所有拨打接通成功>30s的通话数的统计" background="#F19149" title="有效量"
                        :content="dataAnalysisData.calledEffective" unit="个"></AnalysisCard>
          <!--   所有领取首咨的机会数统计   -->
          <AnalysisCard tooltip="所有领取首咨的机会数统计" background="#F19149" title="索取首咨总量" :content="dataAnalysisData.receiveFirst"
                        unit="个"></AnalysisCard>
        </el-row>
      </el-header>
      <el-main>
          <div style="display: flex;flex-direction: row;align-content: center;margin: 10px 0;justify-content: space-between">
              <el-radio-group size="mini" v-model="opporTypeRadios" @change="doSearchByType">
                  <el-radio-button border="true" size="mini" label="">全部</el-radio-button>
                  <el-radio-button border="true" size="mini" label="1">首咨</el-radio-button>
<!--                  <el-radio-button border="true" size="mini" label="2">个人</el-radio-button>-->
<!--                  <el-radio-button border="true" size="mini" label="3">常规</el-radio-button>-->
                  <el-radio-button border="true" size="mini" label="4">库存</el-radio-button>
                  <el-radio-button border="true" size="mini" label="5">分配</el-radio-button>
                  <el-radio-button border="true" size="mini" label="6">预约</el-radio-button>
              </el-radio-group>

              <div style="display: flex;flex-direction: row;align-items: center">
                  <div style="display: flex;flex-direction: row;align-items: center;">
                      <span style="font-size: 14px;width: 100px">最后拨打时间</span>
                      <el-date-picker
                          @change="sumAnalysis()"
                              v-model="searchParams.collectionTime"
                              type="daterange"
                              size="small"
                              value-format="yyyy-MM-dd"
                              range-separator="至"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期">
                      </el-date-picker>
                  </div>
                  <el-button size="small" @click="doSearchByType();">搜索</el-button>
              </div>
          </div>

        <el-row>
          <el-table :data="tableData" max-height="660" style="width: 100%" stripe fit @sort-change="handleSortChange"
                    v-loading="loading">
            <el-table-column align="center" prop="telephone" label="手机号" fixed="left" width="150"></el-table-column>
            <el-table-column align="center" prop="pondId" label="线索池" >
              <template v-slot="scope">
                <span v-for="pond in pondList" :key="pond.id"
                      v-if="pond.id === scope.row.pondId">{{ pond.name }}</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="type" label="类型" width="180">
              <template slot-scope="slot">
                <span v-if="slot.row.type === 1">首咨</span>
                <span v-if="slot.row.type === 2">个人</span>
                <span v-if="slot.row.type === 3">常规</span>
                <span v-if="slot.row.type === 4">库存</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="intention" label="意向度" width="180"></el-table-column>
            <!-- TODO:-->
            <el-table-column align="center" prop="weiXin" width="120" label="微信添加状态">
              <template slot-scope="slot">
                <span v-if="slot.row.isAddWechat === 0 || slot.row.isAddWechat === null">
                  {{ slot.row.addWechatTimeOut }}天未添加
                </span>
                <span :style="{color:'#67C23A' }" v-else>已添加</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="subjectName" label="项目"></el-table-column>
            <!-- TODO:-->
            <el-table-column align="center" prop="mediatorName" label="来源"></el-table-column>
            <!--            communicationTime-->
            <el-table-column align="center" label="当日拨打状态" width="120">
              <template slot-scope="slot">
                <span v-if="slot.row.dialingStatus === 0">未拨打</span>
                <span :style="{color:'#F56C6C'}" v-if="slot.row.dialingStatus === 1">已拨打-已接通</span>
                <span :style="{color:'#67C23A'}" v-if="slot.row.dialingStatus === 2">已拨打-未接通</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="communicationTime" label="当日沟通时长" width="150">
              <template slot-scope="slot">
                {{ formatSeconds(slot.row.communicationTime) }}
              </template>
            </el-table-column>
            <el-table-column align="center" label="当日拨打次数" width="120">
              <template slot-scope="slot">
                <span>{{ slot.row.dailCountToday }}次</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="acquisitionMethod" label="领取方式">
              <template slot-scope="slot">
                <span v-if="slot.row.type === 1">领取</span>
                <span v-if="slot.row.type === 2">分配</span>
                <span v-if="slot.row.type === 3">添加</span>
              </template>
            </el-table-column>
            <el-table-column align="center" prop="remark" label="最后备注"></el-table-column>
            <el-table-column align="center" prop="lastTime" label="最后操作时间" sortable width="150"></el-table-column>
            <el-table-column align="center" prop="dailTime" label="最后拨打时间" sortable width="150"></el-table-column>
            <el-table-column align="center" prop="executeTime" label="回库时间" sortable width="150"></el-table-column>
            <el-table-column align="center" prop="collectionTime" label="领取时间" sortable
                             width="150"></el-table-column>

            <el-table-column align="center" prop="verifyAge" label="年龄"></el-table-column>
            <el-table-column align="center" prop="studentName" label="姓名" width="250"></el-table-column>
            <el-table-column align="center" prop="address" label="地域"></el-table-column>

            <el-table-column align="center" prop="orderStatus" label="支付状态">
              <template slot-scope="slot">
                <span v-if="slot.row.orderStatus === 0">未成单</span>
                <span v-if="slot.row.orderStatus === 1">已成单</span>
                <span v-if="slot.row.orderStatus === 2">已下单</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="今日预约">
              <template slot-scope="slot">
                <span v-if="slot.row.isAppointment === 0">无</span>
                <span v-if="slot.row.isAppointment === 1">有</span>
              </template>
            </el-table-column>
            <el-table-column align="center" label="操作" fixed="right" width="150">
              <template slot-scope="scope">
                <el-button type="primary" size="small" @click="toGiveUp(scope.row.id)">回库</el-button>
                <el-button type="info" size="small" @click="toAllot(scope.row.id,scope.row.telephone)">分配</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <el-row>
          <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="page.current"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="page.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="page.total">
          </el-pagination>
        </el-row>
      </el-main>
    </el-container>
    <Allot v-if="allotObj.showObj.isShow"
           :opportunity-list="allotObj.opportunityList"
           :type="allotObj.type"
           :show-obj="allotObj.showObj"
           :choose-list="allotObj.chooseList"
           @reload="reload">
    </Allot>
  </el-container>
</template>

<script>
import AdviserCard from "./components/AdviserCard";
import GgdCard from "../../components/GgdCard";
import Allot from "./components/Allot";
import * as api from "../../utils/api";
import * as analysisApi from "@/api/crm/data/data-analysis";
import * as accountApi from "@/api/system/account";
import AnalysisCard from "../../components/AnalysisCard.vue";
import * as clueApi from "@/api/clue/cluePond";

export default {
  name: "Team",
  components: {AnalysisCard, GgdCard, AdviserCard, Allot},
  data() {
    return {
      dataAnalysisData:{
          revenue: 0,
          receiveFirst: 0,
          calledEffective: 0,
          connected: 0,
          called: 0,
          calledTotal: 0,
          orderSuccess: 0,
          orderTotal: 0,
          repairRevenue: 0,
      },
      tableData: [],
      tabData: '',
      sortRule: {prop: null, order: null},
      deptSelectData: [],
      adviserData: [],
      adviserAllList: [],
      deptId: 1,
      condition: {}, // 搜索条件
      page: {current: 1, size: 10, total: 0},
      adviserPage: {current: 0, size: 10, total: 0},
      amount: 0,
      orderTotalCount: 0,
      orderSuccessCount: 0,
      duration: "",
      dail: 0,
      effectiveQuantity: 0,
      connectionVolume: 0,
      consultCount: 0,
      opporTypeRadios: '',
      prevLiIndex: null,
      kcuserId: 0,
      loading: true,
      dailTimeStart: '',
      dailTimeEnd: '',
      times: 0,
      allotObj: {
        opportunityList: [],//分配或修改唯一配置的list
        chooseList: [],// 学习顾问列表，或者 唯一配置列表
        type: 1,
        showObj: {
          isShow: false
        }
      },
        // 搜索筛选
        searchParams: {
            collectionTime: [this.formatTimes(new Date(new Date().getTime() - (7 * 24 * 60 * 60 * 1000))), this.formatTimes(new Date())]
        },
      pondList:[],
    }
  },
  methods: {
    findPondList() {
      clueApi.findCluePond({}).then(res => {
        if (res.success) {
          this.pondList = res.data
          this.pondList.unshift({id:1,name:'基础池'})
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    sumAnalysis(deptId){
      let params = {}
      if(this.searchParams && this.searchParams.collectionTime){
        let startTime = this.searchParams.collectionTime[0]
        let endTime = this.searchParams.collectionTime[1]
        params = {"startTime":startTime,endTime:endTime}
      }
      params['deptId'] = deptId || this.deptId
        analysisApi.sumAnalysis(params).then(res => {
            this.dataAnalysisData = res.data
        })
    },
    formateTime(time) {
      if (time < 1) {
        return '00:00:00'
      }
        const h = parseInt(time / 3600)
        const minute = parseInt(time / 60 % 60)
        const second = Math.ceil(time % 60)

        const hours = h < 10 ? '0' + h : h
        const formatSecond = second > 59 ? 59 : second
        return `${hours > 0 ? `${hours}:` : ''}${minute < 10 ? '0' + minute : minute}:${formatSecond < 10 ? '0' + formatSecond : formatSecond}`
    },
    formatTimes(date){
        // 获取各种类型当前时间
        var year = date.getFullYear();    // 获取年
        var month = date.getMonth() + 1;   //获取当前月
        month = month<10?'0'+month:month
        var day = date.getDate();   //日
        day = day<10?'0'+day:day
        var hour = date.getHours();  //时
        var minute = date.getMinutes();    //分
        var second = date.getSeconds();   //秒
        return year + "-" + month + "-" + day
    },
    load: function () {
      this.adviserPage.current++
      this.initDept()
    },
    handleSizeChange(val) {
      this.page.size = val
      this.reload()
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.reload()
    },
    /**
     * 机会类型搜索
     */
    doSearchByType() {
      this.condition['type'] = this.opporTypeRadios
      if (this.searchParams.collectionTime && this.searchParams.collectionTime.length > 0) {
          this.condition.dailTimeStart = this.searchParams.collectionTime[0] + ' 00:00:00'
          this.condition.dailTimeEnd = this.searchParams.collectionTime[1] + ' 23:59:59'
      }else{
          this.condition.dailTimeStart = ''
          this.condition.dailTimeEnd =''
      }
      this.doSearch(this.condition)
      this.adviserData = null
      this.adviserPage.current = 1
      this.initAdviser(this.deptId)
      // this.initDeptHead(this.deptId)
      this.sumAnalysis(this.deptId)
    },
    /**
     * 搜索条件搜索
     */
    doSearch(params) {
      this.page.current = 1
      this.condition = params
      this.loading = true
      this.adviserOpportunity(params)
    },
    adviserOpportunity(params) {
      this.loading = true
      params['kcuserId'] = this.kcuserId
      params['pondId'] = 0
      api.customerAdviserList(this.page.current, this.page.size, params).then(res => {
        // console.log(res)
        if (res.success) {
          let resEntity = res.data
          this.page.current = resEntity.current
          this.page.size = resEntity.size
          this.page.total = resEntity.total
          this.tableData = resEntity.records
          this.tabData = resEntity.records
          if (this.sortRule.order !== null && this.sortRule.prop != null) {
            this.handleSortChange(this.sortRule)
          }
          this.loading = false
        } else {
          this.$message.warning(res.msg);
        }
      }).catch(error => {
        console.log(error)
        this.$message.error("获取我的机会失败");
      })
    }, handleSortChange: function (column) {
      // dailTime  executeTime collectionTime
      let columnName = column.prop
      if (column.order !== null) {
        let data = []
        for (let i = 0; i < this.tableData.length; i++) {
          //判断如果是空的 push在数字后面，有值的放在前面，然后把排好的数级再同仁给表格data
          if (this.tableData[i][columnName]) {
            data.unshift(this.tableData[i])
          } else {
            data.push(this.tableData[i])
          }
        }
        this.tableData = data
      }
      //如果不排序，恢复到最初未排序的状态
      if (column.order === null) {
        this.tableData = this.tabData
      }
      //把当前排序的规则记录下来，给翻页时提供了默认排序条件
      this.sortRule.order = column.order
      this.sortRule.prop = column.prop
    },
    /**
     * 重新刷新页面
     * @param params
     */
    reload() {
      this.loading = true
      this.adviserOpportunity(this.condition)
    },
    handleCardStyle(index, accountId) {
      let obj = this.$refs[`card-${this.prevLiIndex}`]
      if (!obj) {
        this.prevLiIndex = index
        return
      }
      obj[0].checked = false
      //上一次单击的索引
      this.prevLiIndex = index
      this.kcuserId = accountId
      this.adviserOpportunity(this.condition)
    },
    initDept() {
      api.deptManageList().then(res => {
        if (res.success) {
          this.deptSelectData = res.data
          // if (this.times === 0) {
          //   this.deptId = this.deptSelectData[0].id
          //   this.times = 1
          // }
          //获取学习顾问列表
          this.initAdviser(this.deptId)
          //获取部门头部信息
          // this.initDeptHead(this.deptId)
          this.sumAnalysis(this.deptId)
        } else {
          this.$message.warning(res.msg)
        }
      }).catch(error => {
        this.$message.error("获取部门列表失败")
      })
    },
    initAdviser(deptId) {
      let params = {}
      if(this.searchParams && this.searchParams.collectionTime){
          let startTime = this.searchParams.collectionTime[0]
          let endTime = this.searchParams.collectionTime[1]
          params = {"startTime":startTime,endTime:endTime}
      }
      api.deptStatistics(this.adviserPage.current, this.adviserPage.size, deptId, params).then(res => {
        if (res.success) {
          let resEntity = res.data
          if(this.adviserData && deptId === this.deptId){
            this.adviserData = this.adviserData.concat(resEntity.records)
          }else{
            this.adviserData = resEntity.records
          }
          this.adviserPage.current = resEntity.current
          this.adviserPage.size = resEntity.size
          //加载第一个学习顾问的信息
          if (this.adviserData[0]) {
            this.kcuserId = this.adviserData[0].accountId
            this.adviserOpportunity(this.condition)
          }
        }
      }).catch(error => {
        console.log(error)
        this.$message.error("获取部门下的学习顾问列表失败")
      })
    },
    // initDeptHead(deptId) {
    //   let params = {}
    //   if(this.searchParams && this.searchParams.collectionTime){
    //       let startTime = this.searchParams.collectionTime[0]
    //       let endTime = this.searchParams.collectionTime[1]
    //       params = {"startTime":startTime,endTime:endTime}
    //   }
    //   api.deptHeadSatist(deptId,params).then(res => {
    //     console.log('deptHeadSatist',res)
    //     if (res.success) {
    //       this.amount = res.data.totalAmount
    //       this.orderTotalCount = res.data.orderCount
    //       this.orderSuccessCount = res.data.oddNumber
    //       this.duration = this.formatSeconds(res.data.totalDuration)
    //       this.dail = res.data.callCount
    //       this.effectiveQuantity = res.data.connectionCount
    //       this.connectionVolume = res.data.effectiveCount
    //       this.consultCount = res.data.consultCount
    //     } else {
    //       this.$message.warning(res.msg)
    //     }
    //   }).catch(error => {
    //     this.$message.error("获取部门头部数据失败")
    //   })
    // },
    toAllot: function (id, telephone) {
      this.allotObj.chooseList = this.adviserList
      this.allotObj.opportunityList = [{id: id, telephone: telephone}]
      this.allotObj.type = 1
      this.allotObj.showObj.isShow = true
    },
    getAdviserList: function () {
      accountApi.adviserList().then(res => {
        if (res.success) {
          this.adviserList = res.data
        }
      })
    },
    handleDeptChange(val) {
      this.adviserData = []
      this.adviserPage.current = 1
      this.initAdviser(val)
      // this.initDeptHead(val)
      this.sumAnalysis(val)
    },
    toGiveUp(id) {
      if (id) {
        api.giveUp([id]).then(res => {
          if (res.success) {
            this.$message.success("操作成功")
            this.reload()
          }
        })
      }
    },
  },
  mounted() {
    this.findPondList()
    this.handleCardStyle(0);
    this.getAdviserList()
  }
}
</script>

<style scoped>

ul {
  height: 506px;
  list-style: none;
  margin: 0;
}

.el-aside {
  height: 90vh;
  overflow: hidden;
}

.el-container {
  height: 90vh;
}

.infinite-list {
  height: 88vh;
  padding: 0;
}
</style>
