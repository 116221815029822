import request from '@/utils/request.js';

const url = "/manage/crm/data-analysis";

/**
 * 查询列表数据
 */
export function analysis(params) {
  return request.post(`${url}/analysis`, JSON.stringify(params));
}

/**
 * 查询统计数据
 * @param params
 */
export function sumAnalysis(params){
  return request.post(`${url}/sum-analysis`, JSON.stringify(params));
}

/**
 * 查询列表数据
 */
export function todayCache() {
  return request.get(`${url}/today-cache`);
}


/**
 * 查询部门数据统计
 */
export function analysisDeptSum(params) {
  return request({
    url: `${url}/analysisDeptSum`, method: 'get', params: params
  })
}

/**
 * 查询部门数据统计
 */
export function analysisDept(params) {
  return request({
    url: `${url}/analysisDept`, method: 'get', params: params
  })
}