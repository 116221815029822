<template>
  <el-card class="box-card" :shadow="handleShadow"
           :style="{background:background,cursor:handleShadow?'pointer':'', width: width}"
           :body-style="{padding: '10px',height: '100%', boxSizing: 'border-box'}">
    <div @click="handleClick" style="display: flex;flex-direction: column;height: 100%;justify-content: space-between">
      <div style="display: flex;flex-direction: row;justify-content: flex-start;">
        <!--        <span style="word-break:keep-all;white-space:nowrap;" class="title" v-text="title"></span>-->
        <!--        <el-popover trigger="hover" placement="top">-->
        <!--          <p>姓名: </p>-->
        <!--          <div slot="reference" class="name-wrapper">-->
        <!--            <el-tag size="medium">{{ title }}</el-tag>-->
        <!--          </div>-->
        <!--        </el-popover>-->
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip" placement="top-start">
          <span style="word-break:keep-all;white-space:nowrap;" class="title" v-text="title"></span>
        </el-tooltip>
        <span v-else style="word-break:keep-all;white-space:nowrap;" class="title" v-text="title"></span>
      </div>
      <div style="display: flex;flex-direction: row;justify-content: flex-end;">
        <span class="font">{{ content }}</span> <span class="unit">{{ unit }}</span>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  props: {
    background: {
      type: String
    },
    tooltip: {
      type: [String],
      default: null
    },
    content: {
      type: [String, Number]
    },
    unit: {
      type: String
    },
    title: {
      type: String
    },
    handleClick: {
      type: Function,
      default: function () {
      }
    },
    handleShadow: {
      type: String
    },
    width: {
      type: String,
      default: '100px'
    }
  },
  name: "GgdCard",
  data() {
    return {}
  }
}
</script>

<style scoped>


.box-card {
  /*height: 62px;*/
  border-radius: 6px;
  margin: 5px 3px 15px 0px;
  display: inline-block;
}

.font {
  font-size: 18px;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 31px;
  font-family: Microsoft YaHei, serif;
}

.unit {
  font-size: 12px;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 31px;
  font-family: Microsoft YaHei, serif;
}

.title {
  width: 100%;
  height: 13px;
  font-size: 12px;
  font-family: Microsoft YaHei, serif;
  font-weight: 400;
  color: #FFFFFF;
}

</style>
