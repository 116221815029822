<template>
  <el-row>
    <el-col>
      <el-card class="box-card"
               :class="checked ? 'checked' :''"
               @click.native="checkedCardStyle">
        <el-row slot="header" class="clearfix">
          <el-row>
            <el-col :span="12">
              <span class="name" >{{nickName}}</span>
            </el-col>
            <el-col :span="12">
              <span class="configure" v-if="configName">{{configName}}</span>
            </el-col>
          </el-row>
        </el-row>
        <el-row type="flex" justify="space-around">
          <el-col><span class="text">流水：{{totalAmount/100}}</span></el-col>
          <el-col><span class="text">时长：{{ (totalDuration / 60).toFixed(2)}}分</span></el-col>
        </el-row>
        <el-row type="flex" justify="space-around">
          <el-col><span class="text">成单量：{{orderCountNum}}</span></el-col>
          <el-col><span class="text">拨打量：{{dailCountNum}}</span></el-col>
        </el-row>
      </el-card>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "AdviserCard",
  props: {
    index: {
      type: Number,
      require: false
    },
    totalAmount:{ //流水
      type:Number,
      require:false,
      default:0.00
    },totalDuration:{ //拨打时长
      type:Number,
      require:false,
      default:0
    },orderCountNum:{ //成单量
      type:Number,
      require:false,
      default:0
    },dailCountNum:{ //拨打量
      type:Number,
      require:false,
      default:0
    },nickName:{
      type:String,
      require:false,
      default:""
    },configName:{
      type:String,
      require:false,
      default:""
    }
  },
  data() {
    return {
      checked: false
    }
  },
  methods: {
    checkedCardStyle(){
      this.checked = true
    }
  },
  mounted() {
    if(this.index === 0){
      this.checked = true
    }
  }
}
</script>

<style scoped>
.name {
  font-size: 16px;
  font-weight: bold;
  padding: 2px;
}

.configure {
  background-color: #E6A23C;
  font-size: 12px;
  padding: 2px;
  border-radius: 2px;
}

.text {
  font-size: 14px;
}

.checked {
  background-color: #DCDFE6;
}

.el-card:hover {
  background-color: #DCDFE6;
}

/deep/ .clearfix {
  height: 10px;
  line-height: 10px;
  vertical-align: middle;
}

</style>
